import * as BABYLON from "babylonjs"
import * as GUI from "babylonjs-gui"
import { Panel } from "./Panel"
import { eventTask, tuple, pair, Global } from "../global"

interface SliderArray {
	[key: number]: RangeSlider
}

type spatialMessage = {
	source: string,
	payload: number
}

type funcChange = (value: number) => void

export class RangeSlider {

	static currentID = 1
	static all: SliderArray = {}

	id = 0
	colour = "#f58d2c"
	accentColour = "#000000"
	min = 0
	max = 100
	initialValue = 50
	mesh: BABYLON.Mesh|null = null
	onChange: funcChange|null = null
	eventParent: eventTask|null = null
	name = ""
	margin = 0
	nodeType = ""
	texture: GUI.AdvancedDynamicTexture|null = null
	parent: Panel|null = null
	parentID = ""	
	position: tuple = [0,0,0]
	size: pair|null = null
	messageName = ""
	color = ""

	static create(child: ChildNode) {
		const objSlider = new RangeSlider(child)
		RangeSlider.all[objSlider.id] = objSlider
	}

	constructor(child: ChildNode) {
		this.id = RangeSlider.currentID++
		const element = child as HTMLImageElement 
		// Extract attributes
		if (element.hasAttribute("data-spatial-value")) this.initialValue = parseInt(element.getAttribute("data-spatial-value")!)
		if (element.hasAttribute("data-spatial-size")) this.size = JSON.parse("["+element.getAttribute("data-spatial-size")!+"]")
		if (element.hasAttribute("data-spatial-onchange")) this.messageName = element.getAttribute("data-spatial-onchange")!
		if (element.hasAttribute("data-spatial-color")) this.color = element.getAttribute("data-spatial-color")!
		if (element.hasAttribute("data-spatial-min")) this.min = parseInt(element.getAttribute("data-spatial-min")!)
		if (element.hasAttribute("data-spatial-max")) this.max = parseInt(element.getAttribute("data-spatial-max")!)
		if (element.hasAttribute("data-spatial-initial")) this.initialValue = parseInt(element.getAttribute("data-spatial-initial")!)
		this.position = JSON.parse("["+element.getAttribute("data-spatial-position")!+"]")
		this.parentID = element.getAttribute("data-spatial-parent")!

		// Extract styles
		const margin = window.getComputedStyle(element).getPropertyValue("--spatial-margin")
		if (margin !== "") this.margin = parseInt(margin)

		this.eventParent =  (event: MessageEvent) => { 
			const data = event.data
			if (data.hasOwnProperty("source")) {
				if (data.source === "spatialParent") {
					if (data.payload === this.parentID) {
						this.parent = Panel.all[this.parentID]
						this.processParent()
					}
				}
			}
		}

		if (this.parentID) {
			this.parent = Panel.all[this.parentID]
			if (!this.parent) {
				window.addEventListener("message",this.eventParent)
			} else {
				this.processParent()
			}
		}
	}

	processParent() {
		this.mesh = BABYLON.MeshBuilder.CreatePlane("spatial", { width: this.size![0], height: this.size![1] }, Global.scene)
		this.mesh.id = "slider_" + this.id
		this.mesh.position = new BABYLON.Vector3(this.position[0], this.position[1], this.position[2])

		// Create an advanced texture that will be used as the GUI container
		this.mesh.material = new BABYLON.PBRMaterial("matSlider_"+this.id, Global.scene);
		this.texture = GUI.AdvancedDynamicTexture.CreateForMesh(this.mesh)
		// Create the slider
		const slider = new GUI.Slider()
		slider.minimum = this.min
		slider.maximum = this.max
		slider.value = this.initialValue
		slider.horizontalAlignment = GUI.Control.HORIZONTAL_ALIGNMENT_CENTER
		slider.verticalAlignment = GUI.Control.VERTICAL_ALIGNMENT_CENTER
		slider.height = "100%"
		slider.width = "100%"
		slider.color = this.colour
		slider.background = this.accentColour
		slider.isVertical = false


		slider.onValueChangedObservable.add((newValue) => {
			let roundedNum = newValue / 100
			const msg: spatialMessage = {
				source: this.messageName,
				payload: roundedNum
			}
			postMessage(msg,"*")
		})
		// Add the slider to the advanced texture
		this.parent = Panel.all[this.parentID as any]
		this.texture.addControl(slider)
		this.mesh.parent = this.parent!.mesh
		this.mesh.renderingGroupId = 2
	}
}
