import * as BABYLON from "babylonjs"
import * as GUI from "babylonjs-gui"
import { Panel } from "./Panel"
import { eventTask, tuple, pair, Global } from "../global"

interface TextArray {
	[key: number]: TextBlock3D
}


export class TextBlock3D {
	static currentID = 1
	static all: TextArray = {}
	
	id = 0
	name = ""
	mesh: BABYLON.Mesh|null = null
	size: pair|null = null
	sizeMargined: pair|null = null
	position: tuple = [0,0,0]
	orbit: tuple = [0,0,0]
	texture: GUI.AdvancedDynamicTexture|null = null
	alignment = "left"
	gui: GUI.TextBlock|null = null
	nodeType = ""
	parent: Panel|null = null
	parentID = ""
	fontSize = 70
	fontWeight = "normal"
	text = ""
	color = "#000000"
	margin = 0
	eventParent: eventTask|null = null

	static create(child: ChildNode) {
		const objText = new TextBlock3D(child)
		TextBlock3D.all[objText.id] = objText
	}
	
	static clear() {
		for (let key in TextBlock3D.all) {
			window.removeEventListener("message",TextBlock3D.all[key].eventParent!)
			TextBlock3D.all[key].mesh!.dispose(false, true)
			TextBlock3D.all[key].mesh = null
			delete TextBlock3D.all[key]
		}
		TextBlock3D.all = {}
		TextBlock3D.currentID = 1
	}

	constructor(child: ChildNode) {
		this.id = TextBlock3D.currentID++
		const element = child as HTMLElement 
		this.nodeType = child.nodeName
		this.text = child.textContent!

		// Extract attributes
		if (element.hasAttribute("data-spatial-size")) this.size = JSON.parse("["+element.getAttribute("data-spatial-size")!+"]")
		if (element.hasAttribute("data-spatial-orbit")) this.orbit = JSON.parse("["+element.getAttribute("data-spatial-orbit")!+"]")
		this.position = JSON.parse("["+element.getAttribute("data-spatial-position")!+"]")
		this.parentID = element.getAttribute("data-spatial-parent")!

		// Extract styles
		const textAlign = window.getComputedStyle(element).getPropertyValue("text-align")
		if (textAlign !== "") this.alignment = textAlign
		const fontSize = window.getComputedStyle(element).getPropertyValue("--spatial-font-size")
		if (fontSize !== "") this.fontSize = parseInt(fontSize)
		const color = window.getComputedStyle(element).getPropertyValue("--spatial-color")
		if (color !== "") this.color = color
		const margin = window.getComputedStyle(element).getPropertyValue("--spatial-margin")
		if (margin !== "") this.margin = parseInt(margin)
		const fontWeight = window.getComputedStyle(element).getPropertyValue("--spatial-font-weight")
		if (fontWeight !== "") this.fontWeight = fontWeight

		this.eventParent = (event: MessageEvent) => {
			const data = event.data
			if (data.hasOwnProperty("source")) {
				if (data.source === "spatialParent") {
					if (data.payload === this.parentID) {
						this.parent = Panel.all[this.parentID]
						this.processParent()
					}
				}
			}
		}
		  
		if (this.parentID) {
			this.parent = Panel.all[this.parentID as any]
			if (!this.parent) {
				window.addEventListener("message", this.eventParent)
			} else {
				this.processParent()
			}
		} else {
			if (!this.size) {
				this.size = [1,1]
			}
			this.makeMesh()
			this.addGUI()
		}
	}

	processParent() {
		this.parent = Panel.all[this.parentID as any]
		this.parent.children.push(this)
		if (!this.size) {
			this.size = this.parent.size
		}
		this.makeMesh()
		this.addGUI()
	}

	makeMesh() {
		const doubleSides = (this.margin / Global.marginScale) / this.size![0] / 2
		this.sizeMargined = [this.size![0] * (1-doubleSides), this.size![1] * (1-doubleSides)]
		this.mesh = BABYLON.MeshBuilder.CreatePlane("spatial",{ width: this.sizeMargined[0], height: this.sizeMargined[1] }, Global.scene)
		this.mesh.id = "text_" + this.id
		if (this.parent) {
			this.mesh.position = new BABYLON.Vector3(this.position[0], this.position[1], this.position[2])
			this.mesh.parent = this.parent!.mesh
		} else {
			this.mesh.position = new BABYLON.Vector3(0, Global.scene.activeCamera!.position.y + Global.floor, this.orbit[2])
			const pivot = Global.scene.activeCamera!.position
			this.mesh.rotateAround(pivot, BABYLON.Axis.X, this.orbit[1] * Math.PI / 180)
			this.mesh.rotateAround(pivot, BABYLON.Axis.Y, this.orbit[0] * Math.PI / 180)		
		}
		this.mesh.renderingGroupId = 2
		this.mesh.isPickable = false
	}

	addGUI() {
		// Create an advanced texture for this plane
		this.texture = GUI.AdvancedDynamicTexture.CreateForMesh(this.mesh!, 1024, 1024)
		this.gui = new GUI.TextBlock()
		this.gui.text = this.text
		this.gui.fontFamily = "Nunito, sans-serif"
		const sizeMultiplier = 0.6
		const adjustedFontSize = Math.round(this.fontSize / (sizeMultiplier * this.sizeMargined![0]))
		this.gui.fontSize = adjustedFontSize + "px" // Font size in pixels for consistency
		this.gui.fontWeight = this.fontWeight
		this.gui.color = this.color
		// Align text within its container, not stretching it
		this.gui.textVerticalAlignment = GUI.Control.VERTICAL_ALIGNMENT_TOP
		switch(this.alignment) {
			case "left":
				this.gui.horizontalAlignment = GUI.Control.HORIZONTAL_ALIGNMENT_LEFT
				this.gui.textHorizontalAlignment = GUI.Control.HORIZONTAL_ALIGNMENT_LEFT
				break
			case "right":
				this.gui.horizontalAlignment = GUI.Control.HORIZONTAL_ALIGNMENT_RIGHT
				this.gui.textHorizontalAlignment = GUI.Control.HORIZONTAL_ALIGNMENT_RIGHT
				break
			default:
				this.gui.horizontalAlignment = GUI.Control.HORIZONTAL_ALIGNMENT_CENTER
				this.gui.textHorizontalAlignment = GUI.Control.HORIZONTAL_ALIGNMENT_CENTER
			}
		this.gui.verticalAlignment = GUI.Control.VERTICAL_ALIGNMENT_TOP // Align control to top
		// Overflow behavior: text is clipped if it exceeds the container's bounds
		this.gui.textWrapping = true
		this.texture.addControl(this.gui)
	}
}
