import './App.css'
import { Spatialise } from './components/3D/Spatialise'
import CompanyDescription from './components/CompanyDescription'
import Clients from './components/Clients'
import ContactUs from './components/ContactUs'
import Demos from './components/Demos'
import Services from './components/Services'
import MediumStats from './components/MediumStats'
import VR from './components/VR'
import Background from './components/Background'
import VideoPlayer from "./components/video"
import Settings from './components/3D/Settings'

const videoPreview = "assets/video/banner.m3u8"

function HeaderBanner() {
	return(
		<div id="headerBanner">
			<VideoPlayer video={videoPreview} />
		</div>			
	)
}

function App() {
	window.addEventListener("sceneLoadedEvent", Spatialise)

	return (
		<div className="App">
			<Background>
				<HeaderBanner/>
				<CompanyDescription/>
				<MediumStats/>		
				<VR/>
				<Services/>
				<Demos/>
				<Clients/>
				<ContactUs/>				
			</Background>
			<Settings/>
		</div>
	)
}

export default App
