import * as BABYLON from "babylonjs"

export type tuple = [number, number, number]
export type pair = [number, number]
export type eventTask = (event: MessageEvent) => void

export class Global {
	static scene: BABYLON.Scene
	static xr: BABYLON.WebXRDefaultExperience
	static xrPointer: BABYLON.AbstractMesh
	static xrPointerHand: BABYLON.AbstractMesh
	static xrNode: BABYLON.TransformNode
	static colourPickerValue = "#9419CC"
	static dialValue = 90
	static floor = -0.2
	static meshMarcus: BABYLON.AbstractMesh
	static meshMaddie: BABYLON.AbstractMesh
	static showFabric = false
	static parentPanelGrabbable = false
	static parentPlaneDragBehaviour: any
	static parentPlaneDragBehaviour1: any
	static parentPlaneBlock: any
	static parentPlaneBlock1: any
	static parentBlockHighlightColour: string
	static canDrag = false
	static anchored = true
	static dialInteraction = false
	static dialHit: boolean
	static dialPickedPoint: any
	static spatialSliderValue: any
	static spatialOpacitySliderValue: any
	static spatialSizeSliderValue: any
	static spatialDialValueChange: any
	static spatialColourValueChange: any
	static marginScale = 14
	static sphereIdx = 1
	static video: HTMLVideoElement|null

	static debugSphere(position: BABYLON.Vector3, color: BABYLON.Color3): BABYLON.StandardMaterial {
		const sphere = BABYLON.MeshBuilder.CreateSphere("spatial", {diameter: 0.05}, Global.scene)
		sphere.id = "sphere"+Global.sphereIdx
		sphere.position = position
		sphere.material = new BABYLON.StandardMaterial("sphereMat"+Global.sphereIdx++, Global.scene);
		(sphere.material as BABYLON.StandardMaterial).diffuseColor = color
		return sphere.material as BABYLON.StandardMaterial
	}
}
