import * as BABYLON from "babylonjs"
import * as GUI from "babylonjs-gui"
import { tuple, pair, Global } from "../global"

interface PopupArray {
	[key: number]: Popup
}

type funcVoid = () => void

export class Popup {
	static currentID = 1
	static all: PopupArray = []

	id = 0
	mesh: BABYLON.Mesh|null = null
	texture: GUI.AdvancedDynamicTexture|null = null
	gui: GUI.Rectangle|null = null
	onYes: funcVoid|null = null

	static create(prompt: string, onYes: funcVoid) {
		const objPopup = new Popup(prompt, onYes)
		Popup.all[objPopup.id] = objPopup
	}

	constructor(prompt: string, onYes: funcVoid) {
		this.id = Popup.currentID++
		this.onYes = onYes
		this.mesh = BABYLON.MeshBuilder.CreatePlane("spatial",{ width: 1, height: 1 }, Global.scene)
		this.mesh.id = "panel_" + this.id
		this.mesh.renderingGroupId = 2
		this.mesh.parent = Global.scene.activeCamera
		this.mesh.position.z = 0.6
		this.texture = GUI.AdvancedDynamicTexture.CreateForMesh(this.mesh!, 1024, 1024)		

		// Background
		var rect = new GUI.Rectangle()
		rect.width = 0.4
		rect.height = 0.2
		rect.cornerRadius = 20
		rect.color = "White"
		rect.thickness = 4
		rect.background = "#404040"
		this.texture.addControl(rect)

		// Message
		var textBlock = new GUI.TextBlock()
		textBlock.text = prompt
		textBlock.color = "White"
		textBlock.fontSize = 20
		textBlock.top = "-25px"
		textBlock.verticalAlignment = GUI.Control.VERTICAL_ALIGNMENT_TOP
		rect.addControl(textBlock)

		// Button
		var buttonYes = GUI.Button.CreateSimpleButton("butYes", "YES")
		buttonYes.width = 0.2
		buttonYes.height = "40px"
		buttonYes.color = "White"
		buttonYes.background = "Green"
		buttonYes.top = "50px"
		buttonYes.onPointerUpObservable.add(async() => {
			this.texture!.dispose()
			this.remove()
			await Global.xr.baseExperience.exitXRAsync()
			if (this.onYes) this.onYes()
		})
		rect.addControl(buttonYes)
		
		var buttonNo = GUI.Button.CreateSimpleButton("butNo", "NO")
		buttonNo.width = 0.2
		buttonNo.height = "40px"
		buttonNo.color = "White"
		buttonNo.background = "Red"
		buttonNo.top = "50px"
		buttonNo.onPointerUpObservable.add(() => {
			this.remove()
		})
		rect.addControl(buttonNo)
		
		buttonYes.left = "-50px"
		buttonNo.left = "50px"
	}

	remove() {
		this.texture!.dispose()
		this.mesh!.dispose()
		delete Popup.all[this.id]
	}
}