import Setup3DMaddie from "./3D/setupMaddie"

type Props = {
	heading: string, 
	text: string,
	alt: string,
	imageURL: string
}

export default function MediumStats() {
	const path = "assets/medium/"
	const image3D = path+"character3D.png"
	const image2D = path+"character2D.png"
	return(
		<div style={{position:"relative",overflow:"hidden",paddingTop:"calc(700px - 23vw)"}}>
			<div id="honeyComb"/>
			<div className="statContainer">
				<Statistic heading="77%" text="Prefer 3D Games" alt="Graph showing 77%" imageURL="percent77.svg"/>
			</div>
			<div className="statContainer">
				<Statistic heading="23%" text="Prefer 2D Games" alt="Graph showing 23%" imageURL="percent23.svg"/>
			</div>
			<div id="mediumStats">
				<div><Setup3DMaddie/></div>
				<div><img alt="2D image of girl" src={image3D}/></div>
			</div>
		</div>
	)
}

function Statistic({heading, text, alt, imageURL}:Props) {
	const fullURL = "assets/medium/"+imageURL
	return(
		<div className="statistic">
			<div className="featureHeading">
				<img alt={alt} src={fullURL}/>
				<div><h2>{heading}</h2></div>
			</div>
			<hr/>
			<p>{text}</p>
		</div>
	)
}