
export default function VR() {
	const heading = "VIRTUAL REALITY IS BECOMING MORE POPULAR"
	const text = "By default, viewing a website in VR just shows a flat website.\
	 What a waste of all that space!\
	 We can create a spatial website for your brand so users can view your website as if they had an extra 8 monitors around them."
	const imageURL = "experience.jpg"
	const fullURL = "assets/vrSales.svg"
	const alt = "VR chart of sales"
	return(
		<div id="VR">
			<div className="service">
				<div className="serviceText">
					<h1>{heading}</h1>
					<p>{text}</p>
				</div>
				<div id="VRcontainer">
					<div id="VRinfo" className="serviceImage">
						<div id="VRcaption">VR Headset Sales <span style={{color:"var(--primary1)"}}>(2019-2023)</span></div>
						<div className="chartContainer">
							<img alt={alt} src={fullURL}/>
						</div>						
					</div>
				</div>
			</div>
		</div>
	)
}