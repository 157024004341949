import { useMemo, useEffect, useRef, useState } from "react"
import Setup3D from "./3D/setupMain"

let lightningCount = 0

// Custom hook
const useEnter = (buttonRef: React.RefObject<HTMLButtonElement>, shouldAdd = true) => {
	const eventEnter = (event: KeyboardEvent) => {
		if (event.key === "Enter") {
			event.preventDefault()
			buttonRef.current!.click()
		}
	}

	useEffect(() => {
		if (shouldAdd) {
			document.addEventListener("keypress", eventEnter)
		}
		return () => {
			document.removeEventListener("keypress", eventEnter)
		}	
	})
}

function SpatialElements() {
	return (
		<div style={{display:"none"}}>
			{/*Panels in front of camera*/}
			<div data-spatial-parent-name="panel1" data-spatial-orbit={[290, 0, 1.6]}/>
			<div data-spatial-parent-name="panel2" data-spatial-orbit={[330, 0, 1.6]}/>
			<div data-spatial-parent-name="panel3" data-spatial-orbit={[30, 0, 1.6]}/>
			<div data-spatial-parent-name="panel4" data-spatial-orbit={[70, 0, 1.6]}/>
			{/*Panels in behind camera*/}
			<div data-spatial-parent-name="panel5" data-spatial-orbit={[110, 0, 1.6]}/>
			<div data-spatial-parent-name="panel6" data-spatial-orbit={[150, 0, 1.6]}/>
			<div data-spatial-parent-name="panel7" data-spatial-orbit={[210, 0, 1.6]}/>
			<div data-spatial-parent-name="panel8" data-spatial-orbit={[250, 0, 1.6]}/>			
		</div>
	)
}

function Message({message, title, onClose}: {message: Array<string>|string, title: string, onClose: ()=>void}) {
	const refCloseButton = useRef<HTMLButtonElement>(null)
	useEnter(refCloseButton)

	return(
		<div id="contactMessage">
			<div/>
			<div>
				<div className="feature">
					<div className="featureHeading" style={{display:"block"}}>
						<div><h2>{title}</h2></div>
					</div>
					<p>
						{ ((message instanceof Array) && (message.length > 1)) ?
							<ul style={{marginTop:"0"}}>
								{(message as Array<string>).map((item, index) => (
									<li key={index}>{item}</li>
								))}
							</ul>
						: 
							message
						}
					</p>
					<button ref={refCloseButton} type="button" onClick={onClose}>Close</button>
				</div>
			</div>
		</div>
	)
}

function lightning(darkness: HTMLDivElement) {
	setTimeout(() => {
		darkness.classList.remove("blink1")
		darkness.classList.remove("blink2")
		setTimeout(() => {
			if (lightningCount < 5) {
				darkness.classList.add("blink1")	
			} else {
				darkness.classList.add("blink2")	
				lightningCount = 0
			}
			lightningCount++
			lightning(darkness)
		},500)
	}, Math.random() * 2000 + 3000)
}

function ContactMessage() {
	const email1 = "info@"
	const email2 = "punkoffice.com"
	const totalEmail = email1+email2
	const emailLink = "mailto:" + totalEmail

	return(
		<p>
			For urgent enquiries please e-mail&nbsp;
			<a href={emailLink}>{totalEmail}</a>&nbsp;
			or leave us a message by filling out this form
		</p>
	)
}

export default function ContactUs() {
	const [firstName, setFirstName] = useState("")
	const [lastName, setLastName] = useState("")
	const [email, setEmail] = useState("")
	const [phoneNumber, setPhoneNumber] = useState("")
	const [message, setMessage] = useState("")
	const [title, setTitle] = useState("")
	const [canSend, setCanSend] = useState(true)
	const refFirstName = useRef<HTMLInputElement>(null)
	const [popupMessage, setPopupMessage] = useState<Array<string>|string>([])
	const [loaded3D, setLoaded3D] = useState(false)
	const [containerRendered, setContainerRendered] = useState(false)
	const refCanvasContainer = useRef<HTMLDivElement>(null)

	useEffect(() => {
		lightning(refCanvasContainer.current!)
		if (!containerRendered) setContainerRendered(true)
	},[containerRendered])

	const MemoizedSetup3D = useMemo(
		() => <Setup3D container={refCanvasContainer.current!} isLoaded={isLoaded}/>,[containerRendered]
	)

	function clearError() {
		setPopupMessage([])
	}

	function isLoaded() {
		setLoaded3D(true)
	}

	async function sendEmail(e: React.FormEvent<HTMLFormElement>) {
		const patternPhone = /^[0-9+\-\s()]*$/
		const patternEmail = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/
		let formError = false
		let errMessage = []
		e.preventDefault()
		if (firstName.trim() === "") {
			formError = true
			errMessage.push("You must provide a first name")
		}
		if (lastName.trim() === "") {
			formError = true
			errMessage.push("You must provide a last name")
		}
		if (phoneNumber.trim() === "") {
			formError = true
			errMessage.push("You must provide a phone number")
		} else if (!patternPhone.test(phoneNumber)) {
			formError = true
			errMessage.push("Invalid phone number")
		}	if (email.trim() === "") {
			formError = true
			errMessage.push("You must provide an e-mail address")
		} else if (!patternEmail.test(email)) {
			formError = true
			errMessage.push("Invalid e-mail address")
		}
		if (message.trim() === "") {
			formError = true
			errMessage.push("You must provide a message")
		}
		if (formError) {
			setTitle("Incomplete Form")
			setPopupMessage(errMessage)
		} else {
			setCanSend(false)
			const settings = {
				method: 'POST',
				headers: {
					Accept: 'application/json',
					'Content-Type': 'application/json',
				},
				body: JSON.stringify({
					firstName: firstName,
					lastName: lastName,
					email: email,
					phoneNumber: phoneNumber,
					message: message
				})
			}
			try {
				const response = await fetch("/punkoffice_api/contact", settings)
				try {
					const json = await response.json()
					if (json.success === 1) {
						setTitle("Contact Form")
						setFirstName("")
						setLastName("")
						setPhoneNumber("")
						setEmail("")
						setMessage("")
						setPopupMessage("SENT!")						
					} else {
						setPopupMessage(json.message)
					}
				} catch (error: unknown) {
					if (error instanceof Error) {
						setTitle("Contact Form")
						setPopupMessage(error.message)
					}
				}
			} catch (error: unknown) {
				if (error instanceof Error) {
					setTitle("Contact Form")
					setPopupMessage(error.message)
				}
			}
			setCanSend(true)
		}
	}

	function changeForm(field: string, e: React.ChangeEvent<HTMLInputElement>) {
		const inputValue = e.target.value
		switch(field) {
			case "firstName":
				setFirstName(inputValue)
				break
			case "lastName":
				setLastName(inputValue)
				break
			case "email":
				setEmail(inputValue)
				break
			case "phone":
				setPhoneNumber(inputValue)
				break
			case "message":
				setMessage(inputValue)
				break
			default:
				console.log("Error setting form")
			}
	}	

	return(
		<>
			{ (popupMessage.length > 0) &&
				<Message message={popupMessage} title={title} onClose={clearError} />
			}
			{ loaded3D &&
				<>
					<div id="generalsettings"/>
				</>
			}
			<div id="contactUs">
				<div id="contactForm">
					<h3>CONTACT US</h3>
					<ContactMessage/>
					<form onSubmit={sendEmail}>
						<div id="contactFormGrid">
							<div>
								<h4><label htmlFor="firstName">*First name</label></h4>
								<input ref={refFirstName} type="text" id="firstName" value={firstName} onChange={(e) => changeForm("firstName", e)}/>
							</div>
							<div>
								<h4><label htmlFor="lastName">*Last name</label></h4>
								<input type="text" id="lastName" value={lastName} onChange={(e) => changeForm("lastName", e)}/>
							</div>
							<div>
								<h4><label htmlFor="email">*E-mail</label></h4>
								<input type="text" id="email" value={email} onChange={(e) => changeForm("email", e)}/>
							</div>
							<div>
								<h4><label htmlFor="phoneNumber">Phone number</label></h4>
								<input type="text" id="phoneNumber" value={phoneNumber} onChange={(e) => changeForm("phone", e)}/>
							</div>
							<div>
								<h4><label htmlFor="message">*Message</label></h4>
								<input type="text" id="message" value={message} onChange={(e) => changeForm("message", e)}/>
							</div>
							<div>
								<button id="btnContact" disabled={!canSend} type="submit">Send Message</button>
							</div>
						</div>
					</form>
					</div>
				<div id="imageContainer" ref={refCanvasContainer}>
					{ !loaded3D && <div id="loading"/> }
					<div id="imageCaption">Founder - Marcus Milne</div>
					{ containerRendered && MemoizedSetup3D }
				</div>
			</div>
			<SpatialElements/>
		</>
	)
}