import * as BABYLON from "babylonjs"
import * as GUI from "babylonjs-gui"
import { Panel } from "./Panel"
import { eventTask, tuple, pair, Global } from "../global"

interface ButtonArray {
	[key: number]: Button
}

type spatialMessage = {
	source: string,
	payload: string
}

export class Button {
	static currentID = 1
	static all: ButtonArray = {}

	id = 0
	name = ""
	mesh: BABYLON.Mesh|null = null
	size: pair|null = null
	sizeMargined: pair|null = null
	position: tuple = [0,0,0]
	orbit: tuple = [0,0,0]
	texture: GUI.AdvancedDynamicTexture|null = null
	imageGUI:GUI.Image|null = null
	bgColour = "blue"
	textColour = "green"
	nodeType = ""
	parent: Panel|null = null
	parentID = ""
	image = ""
	imageDown = ""
	pickable = false
	value = ""
	margin = 0
	onclick =  ""
	target: GUI.AdvancedDynamicTexture|null = null
	gui: GUI.TextBlock|null = null	
	eventParent: eventTask|null = null
	isInitialImage = true
	obsPointer: BABYLON.Observer<GUI.Vector2WithInfo>|null = null

	static create(child: ChildNode) {
		const objText = new Button(child)
		Button.all[objText.id] = objText		
	}

	static clear() {
		for (let key in Button.all) {
			if (Button.all[key].obsPointer) Button.all[key].obsPointer!.remove()
			window.removeEventListener("message",Button.all[key].eventParent!)
			Button.all[key].mesh!.dispose(false, true)
			Button.all[key].mesh = null
			delete Button.all[key]
		}
		Button.all = []
		Button.currentID = 1
	}

	constructor(child: ChildNode) {
		this.id = Button.currentID++
		const element = child as HTMLButtonElement 
		this.nodeType = child.nodeName
		// Extract attributes
		if (element.hasAttribute("data-spatial-size")) this.size = JSON.parse("["+element.getAttribute("data-spatial-size")!+"]")
		if (element.hasAttribute("data-spatial-orbit")) this.orbit = JSON.parse("["+element.getAttribute("data-spatial-orbit")!+"]")
		if (element.hasAttribute("data-spatial-pickable")) this.pickable = element.getAttribute("data-spatial-pickable")?.toLowerCase() == "true"
		if (element.hasAttribute("data-spatial-image")) this.image = element.getAttribute("data-spatial-image")!
		if (element.hasAttribute("data-spatial-image-down")) this.imageDown = element.getAttribute("data-spatial-image-down")!
		if (element.hasAttribute("data-spatial-onclick")) this.onclick = element.getAttribute("data-spatial-onclick")!
		this.value = element.value

		this.position = JSON.parse("["+element.getAttribute("data-spatial-position")!+"]")
		this.parentID = element.getAttribute("data-spatial-parent")!

		// Extract styles
		const margin = window.getComputedStyle(element).getPropertyValue("--spatial-margin")
		if (margin !== "") this.margin = parseInt(margin)
		const styleColour = window.getComputedStyle(element).getPropertyValue("color")
		if (styleColour) this.textColour = styleColour
		const styleBGcolour = window.getComputedStyle(element).getPropertyValue("background-color")
		if (styleBGcolour) this.bgColour = styleBGcolour

		this.eventParent =  (event: MessageEvent) => { 
			const data = event.data
			if (data.hasOwnProperty("source")) {
				if (data.source === "spatialParent") {
					if (data.payload === this.parentID) {
						this.parent = Panel.all[this.parentID]
						this.processParent()
					}
				}
			}
		}

		if (this.parentID) {
			this.parent = Panel.all[this.parentID]
			if (!this.parent) {
				window.addEventListener("message",this.eventParent)
			} else {
				this.processParent()
			}
		} else {
			this.makeMesh()
		}		
	}

	processParent() {
		this.parent = Panel.all[this.parentID as any]
		this.parent.children.push(this)
		if (!this.size) {
			this.size = this.parent.size
		}
		this.makeMesh()
		this.addGUI()
	}

	makeMesh() {
		const doubleSides = (this.margin / Global.marginScale) / this.size![0] / 2
		this.sizeMargined = [this.size![0] * (1-doubleSides), this.size![1] * (1-doubleSides)]
		this.mesh = BABYLON.MeshBuilder.CreatePlane("spatial",{ width: this.sizeMargined[0], height: this.sizeMargined[1] }, Global.scene)
		this.mesh.id = "button_" + this.id		
		if (this.parent) {
			this.mesh.position = new BABYLON.Vector3(this.position[0], this.position[1], this.position[2])
			this.mesh.parent = this.parent!.mesh
		} else {
			this.mesh.position = new BABYLON.Vector3(0, Global.scene.activeCamera!.position.y + Global.floor, this.orbit[2])
			const pivot = Global.scene.activeCamera!.position
			this.mesh.rotateAround(pivot, BABYLON.Axis.X, this.orbit[1] * Math.PI / 180)
			this.mesh.rotateAround(pivot, BABYLON.Axis.Y, this.orbit[0] * Math.PI / 180)		
		}
		this.mesh.renderingGroupId = 2
	}

	addGUI() {
		// Create an advanced texture for this plane
		this.texture = GUI.AdvancedDynamicTexture.CreateForMesh(this.mesh!, 1024, 1024)

		// Create the main button that will act as the dropdown header
		const button = GUI.Button.CreateSimpleButton("spatial", this.value!)
		this.imageGUI = new GUI.Image("spatial", this.image)

		button.width = "100%"
		button.height = "100%"
		button.color = this.textColour
		button.background = this.bgColour
		this.texture.addControl(button)
		this.texture.hasAlpha = true
		button.textBlock!.fontSize = "30%"
		button.textBlock!.fontWeight = "bold"
		button.textBlock!.fontFamily = "Nunito, sans-serif"
		button.cornerRadius = 200 // This property rounds the edges of the button
		button.thickness = 20 // Sets the thickness of the border
		if (this.image != "") {
			button.textBlock!.text = ""
			this.imageGUI.width = "100%" // Set the width of the image
			this.imageGUI.height = "100%" // Set the height of the image
			this.imageGUI.stretch = GUI.Image.STRETCH_FILL // Adjust the stretch mode as needed
			button.addControl(this.imageGUI) // Add the image control to the button
		}
		// Bind toggle function to header button
		this.obsPointer = button.onPointerClickObservable.add(() => {
			console.log("Observable click: "+this.onclick)
			Global.debugSphere(new BABYLON.Vector3(0.4,1,0.4),BABYLON.Color3.Blue())
			const msg: spatialMessage = {
				source: this.onclick,
				payload: "toggle"
			}
			postMessage(msg,"*")
			if (this.imageDown != "") {
				this.toggleImage()
			}
		})
	}

	toggleImage() {
		if (this.isInitialImage) {
			this.imageGUI!.source = this.imageDown
		} else {
			this.imageGUI!.source = this.image
		}
		this.isInitialImage = !this.isInitialImage
	}
}
