import * as BABYLON from "babylonjs"
import { Panel } from "./Panel"
import { Popup } from "./Popup"
import { eventTask, tuple, pair, Global } from "../global"

interface ImageArray {
	[key: number]: ImageBlock
}

type funcVoid = () => void

export class ImageBlock {
	static currentID = 1
	static all: ImageArray = {}
	static obsPointer: BABYLON.Observer<BABYLON.PointerInfo>|null = null

	id = 0
	name = ""
	mesh: BABYLON.Mesh|null = null
	size: pair|null = null
	sizeMargined: pair|null = null
	position: tuple = [0,0,0]
	orbit: tuple = [0,0,0]
	nodeType = ""
	parent: Panel|null = null
	parentID = ""
	pickable = false
	src = ""
	target = ""
	margin = 0
	eventParent: eventTask|null = null
	eventPointerDown: funcVoid|null = null

	static create(child: ChildNode) {
		const objText = new ImageBlock(child)
		ImageBlock.all[objText.id] = objText
	}
	
	static clear() {
		for (let key in ImageBlock.all) {
			if (ImageBlock.obsPointer) ImageBlock.obsPointer!.remove()
			window.removeEventListener("message",ImageBlock.all[key].eventParent!);
			ImageBlock.all[key].mesh!.dispose(false, true)
			ImageBlock.all[key].mesh = null
			delete ImageBlock.all[key]
		}
		ImageBlock.all = []
		ImageBlock.currentID = 1
	}

	constructor(child: ChildNode) {
		this.id = ImageBlock.currentID++
		const element = child as HTMLImageElement 
		this.nodeType = child.nodeName
		this.src = element.src
		// Extract attributes
		if (element.hasAttribute("data-spatial-size")) this.size = JSON.parse("["+element.getAttribute("data-spatial-size")!+"]")
		if (element.hasAttribute("data-spatial-orbit")) this.orbit = JSON.parse("["+element.getAttribute("data-spatial-orbit")!+"]")
		if (element.hasAttribute("data-spatial-pickable")) this.pickable = element.getAttribute("data-spatial-pickable")?.toLowerCase() == "true"
		if (element.hasAttribute("data-spatial-target")) this.target = element.getAttribute("data-spatial-target")!
		this.position = JSON.parse("["+element.getAttribute("data-spatial-position")!+"]")
		this.parentID = element.getAttribute("data-spatial-parent")!

		// Extract styles
		const margin = window.getComputedStyle(element).getPropertyValue("--spatial-margin")
		if (margin !== "") this.margin = parseInt(margin)
		this.eventParent =  (event: MessageEvent) => { 
			const data = event.data
			if (data.hasOwnProperty("source")) {
				if (data.source === "spatialParent") {
					if (data.payload === this.parentID) {
						this.parent = Panel.all[this.parentID]
						this.processParent()
					}
				}
			}
		}

		if (this.parentID) {
			this.parent = Panel.all[this.parentID]
			if (!this.parent) {
				window.addEventListener("message",this.eventParent)
			} else {
				this.processParent()
			}
		} else {
			this.makeMesh()
		}

	}

	processParent() {
		this.parent = Panel.all[this.parentID as any]
		this.parent.children.push(this)
		if (!this.size) {
			this.size = this.parent.size
		}
		this.makeMesh()
	}

	makeMesh() {
		const doubleSides = (this.margin / Global.marginScale) / this.size![0] / 2
		this.sizeMargined = [this.size![0] * (1-doubleSides), this.size![1] * (1-doubleSides)]
		this.mesh = BABYLON.MeshBuilder.CreatePlane("spatial",{ width: this.sizeMargined[0], height: this.sizeMargined[1] }, Global.scene)
		this.mesh.id = "image_" + this.id		
		if (this.parent) {
			this.mesh.position = new BABYLON.Vector3(this.position[0], this.position[1], this.position[2])
			this.mesh.parent = this.parent!.mesh
		} else {
			this.mesh.position = new BABYLON.Vector3(0, Global.scene.activeCamera!.position.y + Global.floor, this.orbit[2])
			const pivot = Global.scene.activeCamera!.position
			this.mesh.rotateAround(pivot, BABYLON.Axis.X, this.orbit[1] * Math.PI / 180)
			this.mesh.rotateAround(pivot, BABYLON.Axis.Y, this.orbit[0] * Math.PI / 180)		
		}
		this.mesh.renderingGroupId = 2
		this.mesh.isPickable = this.pickable
		// Apply an image texture to the plane
		const material = new BABYLON.StandardMaterial("matImage_"+this.id, Global.scene)
		const texture = new BABYLON.Texture(this.src, Global.scene)
		texture.hasAlpha = true
		material.diffuseTexture = texture
		material.backFaceCulling = false
		material.emissiveColor = BABYLON.Color3.White()
		this.mesh.material = material
		this.mesh.renderingGroupId = 2
	}

	static setupPicking() {
		this.obsPointer = Global.scene.onPointerObservable.add(() => {
			Global.scene.onPointerDown = (evt, pickInfo) => {
				if (pickInfo.hit) {
					if (pickInfo.pickedMesh) {
						const arrPickableMesh = pickInfo.pickedMesh.id.split("_")
						if (arrPickableMesh.length === 2) {
							if (arrPickableMesh[0] === "image") {
								const imageID = Number(arrPickableMesh[1])
								const objImage = ImageBlock.all[imageID]
								if (objImage.target !== "") {
									Popup.create("Are you sure you want to go to\n"+objImage.target+"?",
										() => {
											window.location.href = objImage.target
										}
									)
								}
							}
						}
					}
				}
			}
		})
	}
}
