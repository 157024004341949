const logos = [
	{filename:"100netball.png",invert:1,height:80},
	{filename:"actionavatar.png",invert:0,height:150},
	{filename:"cadrepictures.png",invert:0,height:150},
	{filename:"capebionics.png",invert:1,height:100},
	{filename:"champion_system.png",invert:1,height:100},
	{filename:"cryptovoxels.png",invert:1,height:150},
	{filename:"kajaclothing.png",invert:1,height:100},
	{filename:"ledjen_logo.png",invert:1,height:100},
	{filename:"lulumanna.png",invert:1,height:90},
	{filename:"lvr.png",invert:1,height:100},
	{filename:"mvrk.png",invert:1,height:100},
	{filename:"twogoats.png",invert:1,height:60},
	{filename:"unify_netball.png",invert:1,height:100},
	{filename:"upcomingmedia.png",invert:0,height:100},
	{filename:"vmlyr.png",invert:1,height:100},
	{filename:"ninja_productions_logo.png",invert:1,height:60},
	{filename:"cyberbrokers_logo.png",invert:1,height:40}
]

export default function Clients() {
	const logoPath = "assets/clients/"
	const allLogos = logos.map(({filename, invert, height}, index) =>
		<div key={index} className="client">
			<img src={logoPath+filename} style={{height:height+"%",filter:"invert("+invert+")"}} alt={filename}/>
		</div>
	)
	
	return(
		<div id="clients">
			<div style={{width:"80%",margin:"0 auto",paddingTop:"2vw",paddingBottom:"2vw"}}>
				{allLogos}
			</div>
		</div>
	)
}