import { useEffect, useRef } from "react"
import * as BABYLON from "babylonjs"
import * as MATERIAL from "babylonjs-materials"
import { Facial } from "./facial"
import { Eye } from "./eye"

let engine: BABYLON.Engine|null = null
let cameraRotate: BABYLON.ArcRotateCamera
let meshBody: BABYLON.AbstractMesh
let meshHead: BABYLON.AbstractMesh
let matHeadAtlas: BABYLON.Material
let headAtlas: BABYLON.Nullable<BABYLON.BaseTexture>
let matAtlasURL: string

function setupArcRotateCamera(scene: BABYLON.Scene, canvas: HTMLCanvasElement, target: BABYLON.AbstractMesh) {
	const radius = 14
	const beta = 1.4
	const alpha = -Math.PI / 2
	cameraRotate = new BABYLON.ArcRotateCamera("Camera", alpha, beta, radius, new BABYLON.Vector3(0, 0, 0), scene)
	cameraRotate.lowerBetaLimit = beta - 0.6
	cameraRotate.upperBetaLimit = beta + 0.6
	cameraRotate.lowerRadiusLimit = radius
	cameraRotate.upperRadiusLimit = radius
	cameraRotate.panningSensibility = 0
	cameraRotate.minZ = 0.1
	cameraRotate.wheelPrecision = 100

	cameraRotate.setTarget(target.position)
	cameraRotate.attachControl(canvas)
}

const eventFocus = () => {
	console.log("EVENT FOCUS")
	startAllExpressions()
	startBlinking()
}

const eventBlur = () => {
	console.log("EVENT BLUR")
	clearTimeout(Facial.blinkTimer)
	clearTimeout(Facial.blinkStepTimer)
	clearTimeout(Facial.timerExp)
	Facial.blinkGoingUp = true
	Facial.blinkValue = 0
	Facial.busy = false
	Facial.squinting = false
	Facial.isBlinking = false

	Facial.resetAll()
}

function setupEvents() {	
	window.addEventListener('focus', eventFocus)
	window.addEventListener('blur', eventBlur)
	window.addEventListener("resize", () => {
		if (engine) engine!.resize()
	})					
}

function setupLighting(scene: BABYLON.Scene) {
	const lightHemi = new BABYLON.HemisphericLight("HemiLight", new BABYLON.Vector3(0, 1, 0), scene)
	lightHemi.intensity = 1
}

function setupSkin(scene: BABYLON.Scene) {
    var matSkin = meshHead.material as BABYLON.MultiMaterial
    var matCustom = new MATERIAL.CustomMaterial("skin",scene)

    matCustom.AddUniform('texAtlas','sampler2D',undefined)
    matCustom.AddUniform('texUV','sampler2D',undefined)
    matCustom.AddUniform('percentPout','float',undefined)
    matCustom.AddUniform('percentSmile','float',undefined)
    matCustom.AddUniform('percentSquint','float',undefined)
    matCustom.Fragment_Definitions(`      
    vec4 getTextureFromAtlasMap(sampler2D txtRef_0,vec2 pos,vec2 vuv){

    vec2 size = vec2(2048.,2048.);
    vec2 SIZE = vec2(4096.,4096.);
    float uv_w = size.x / SIZE.x;  
    float uv_h = size.y / SIZE.y;   
    float uv_x = pos.x / SIZE.x ;    
    float uv_y = 1.- pos.y / SIZE.y -uv_h; 

    vec2 newUvAtlas = vec2( mod( vuv.x*uv_w, uv_w )+uv_x, mod(vuv.y*uv_h, uv_h)+uv_y  ); 
    vec4 color  = texture2D(txtRef_0 ,newUvAtlas.xy*vec2(1.,1.)+vec2(0.,0.));

    return color ;
    } `)
    matCustom.diffuseTexture = headAtlas
    matCustom.specularColor = new BABYLON.Color3(0.05,0.05,0.05)
    matCustom.emissiveColor = BABYLON.Color3.White()

    matCustom.onBindObservable.add(() => { 
        matCustom.getEffect().setTexture('texAtlas',headAtlas)
        matCustom.getEffect().setFloat('percentPout',Facial.objFacial.pout.textureVal)
        matCustom.getEffect().setFloat('percentSmile',Facial.objFacial.smile.textureVal)
        matCustom.getEffect().setFloat('percentSquint',Facial.objFacial.squint.textureVal)
    })
    matCustom.Fragment_Before_FragColor(`
    vec4 colDefault = getTextureFromAtlasMap(texAtlas, vec2(0.,2048.), vDiffuseUV);
    vec4 colPout = getTextureFromAtlasMap(texAtlas, vec2(0.,0.), vDiffuseUV);
    vec4 colSmile = getTextureFromAtlasMap(texAtlas, vec2(2048.,0.), vDiffuseUV); 
    vec4 colSquint = getTextureFromAtlasMap(texAtlas, vec2(2048.,2048.), vDiffuseUV); 

    if (percentPout > percentSquint) {
        color = colPout*percentPout + (1.-percentPout)*colDefault;
    } else if (percentSmile > percentSquint) {
        color = colSmile*percentSmile + (1.-percentSmile)*colDefault;
    } else {
        color = colSquint*percentSquint + (1.-percentSquint)*colDefault;
    }
    `);
    (matSkin as BABYLON.MultiMaterial).subMaterials[1] = matCustom
}

function setupMaterials(scene: BABYLON.Scene) {
    //Eyes
	var matEyes = (scene.getMeshByName("EyeRight")!.material as any).subMaterials[0] as BABYLON.StandardMaterial
    var eyeEmissive = new BABYLON.Color3(0.5,0.5,0.5)
    var eyeSpecular = new BABYLON.Color3(0.3,0.3,0.3)

    //Body
    const matBody = {
        ponytail:scene.getMaterialByName("morphs.ponytail") as BABYLON.StandardMaterial,
        teeth:scene.getMaterialByName("morphs.teeth") as BABYLON.StandardMaterial,
        eyelashes:scene.getMaterialByName("morphs.Eyelashes") as BABYLON.StandardMaterial,
        eyesInside:scene.getMaterialByName("morphs.Eye_blue") as BABYLON.StandardMaterial,
		eyesOutside:scene.getMaterialByName("morphs.Eye_outside") as BABYLON.StandardMaterial
    }
	var texBody = new Array(4)
    let i = 0
    for(var w = 0; w < 2; ++w) {
        for(var h = 0; h < 2; ++h) {
            var texture = new BABYLON.Texture("assets/3D/maddie/body_atlas.png", scene, true, true, 3)                
            texture.uScale = 0.5
            texture.vScale = 0.5
            texture.uOffset = w/2
            texture.vOffset = h/2
            texBody[i] = texture
            i++
        }
    } 
	matBody.eyesInside.emissiveColor = eyeEmissive
	matBody.eyesInside.specularColor = eyeSpecular
    matBody.ponytail!.diffuseTexture = texBody[0]
    matBody.teeth!.diffuseTexture = texBody[2]
    matBody.eyelashes!.diffuseTexture = texBody[1]
    matBody.eyelashes!.opacityTexture = texBody[1]
    matBody.eyesInside!.diffuseTexture = texBody[3]
	matEyes.diffuseTexture = texBody[3]
	matEyes.emissiveColor = eyeEmissive
	matEyes.specularColor = eyeSpecular
}

function setupMorphTargets() {
    //Create all facial expression objects
    Facial.blinkTarget = meshHead.morphTargetManager!.getTarget(0)
    Facial.objFacial.squint = new Facial("Squint", meshHead.morphTargetManager!.getTarget(0),0.2)
    Facial.objFacial.pout = new Facial("Pout", meshHead.morphTargetManager!.getTarget(1),0.7)
    Facial.objFacial.smile = new Facial("Smile", meshHead.morphTargetManager!.getTarget(2),0.5)
}

function startBlinking() {
    clearTimeout(Facial.blinkTimer)
    clearTimeout(Facial.blinkStepTimer)
    Facial.blinkTimer = setTimeout(() => {
        Facial.blink()
    },1000)                              
}

var previousExpIdx = 0

function startAllExpressions() {
    clearTimeout(Facial.timerExp)
    Facial.timerExp = setTimeout(() => {
        var randomNum = Math.random()
        var expIdx = Math.round(randomNum*10/2)
        if (previousExpIdx === expIdx) {
            expIdx++
            if (expIdx > 4) expIdx = 0
        }
        previousExpIdx = expIdx;
        switch(expIdx) {
            case 0:
                Facial.objFacial.pout.startExpression()
                break
            case 1:
                Facial.objFacial.squint.startExpression(3000,() => {
                    Eye.bothY(0.1)
                },() => {
                    Eye.bothY(0)
                })
                break
            case 2:
                Facial.objFacial.smile.startExpression()
                break
            case 3:
                Eye.bothX(0.15, true)
                break
            case 4:
                Eye.bothX(-0.15, true)
                break
        }
        startAllExpressions()
    }, (Math.random()+3) * 1000)
}

export function cleanupXR() {
	eventBlur()
	window.removeEventListener("blur", eventBlur)
	window.removeEventListener("focus", eventFocus)
}

export async function importMaddieXR(scene: BABYLON.Scene) {
	if (engine) {
		cleanupXR()
		let oldScene = engine.scenes[0]
		oldScene.materials.forEach(mat => mat.dispose())
		oldScene.meshes.forEach(mesh => mesh.dispose())
		oldScene.dispose()
		engine!.dispose()
		engine = null
	}
	const result = await BABYLON.SceneLoader.ImportMeshAsync(null, "assets/3D/maddie/", "maddie.babylon", scene)
	const maddieParent = new BABYLON.TransformNode("maddieParent", scene)
	const maddieScale = 0.1
	result.meshes.forEach((mesh: BABYLON.AbstractMesh) => mesh.setParent(maddieParent))
	maddieParent.rotation = new BABYLON.Vector3(0, Math.PI, 0)
	maddieParent.scaling = new BABYLON.Vector3(maddieScale, maddieScale, maddieScale)
	maddieParent.position.y = 1.3
	maddieParent.position.z = -1.1

	window["maddie" as any] = maddieParent as any
	const skel = result.skeletons[0]
	skel.beginAnimation("dancing", true)

	meshBody = scene.getMeshByName("MaddieBody")!
	meshHead = scene.getMeshByName("MaddieHead")!
	matHeadAtlas = scene.getMaterialByName("matAtlas")!

	headAtlas = new BABYLON.Texture(matAtlasURL, scene)
	Eye.objEyes.left = new Eye(skel.bones[skel.getBoneIndexByName("Eye.L")])
	Eye.objEyes.right = new Eye(skel.bones[skel.getBoneIndexByName("Eye.R")])

	setupMaterials(scene)
	setupSkin(scene)
	setupMorphTargets()
	startAllExpressions()
	startBlinking()
	eventFocus()
}

let canvasMaddie: HTMLCanvasElement

export function importMaddie() {
	if (!engine) {
		engine = new BABYLON.Engine(canvasMaddie, true)
		engine.setHardwareScalingLevel(0.5)
		BABYLON.SceneLoader.ShowLoadingScreen = false
		BABYLON.SceneLoader.Load("assets/3D/maddie/", "maddie.babylon", engine, (newScene) => {
			newScene.executeWhenReady(async () => {
				const scene = newScene
				scene.clearColor = new BABYLON.Color4(0,0,0,0)
				meshBody = scene.getMeshByName("MaddieBody")!
				meshHead = scene.getMeshByName("MaddieHead")!
				matHeadAtlas = scene.getMaterialByName("matAtlas")!
				headAtlas = (matHeadAtlas as BABYLON.StandardMaterial).diffuseTexture
				matAtlasURL = (headAtlas as BABYLON.Texture).url!
				const skel = scene.skeletons[0]
				Eye.objEyes.left = new Eye(skel.bones[skel.getBoneIndexByName("Eye.L")])
				Eye.objEyes.right = new Eye(skel.bones[skel.getBoneIndexByName("Eye.R")])
			
				setupArcRotateCamera(scene, canvasMaddie, meshBody)
				setupLighting(scene)
				setupEvents()
				setupMaterials(scene)
				setupSkin(scene)
				setupMorphTargets()
				scene.skeletons[0].beginAnimation("dancing", true)
				startAllExpressions()
				startBlinking()
				engine!.runRenderLoop(() => {
					scene.render()
				})
			}, true)
		})
	}
}

export default function Setup3DMaddie() {
	const refCanvas = useRef<HTMLCanvasElement>(null)

	useEffect(() => {
		canvasMaddie = refCanvas.current!
		importMaddie()
	})

	return(
		<canvas id="maddieCanvas" ref={refCanvas}/>
	)
}