import * as BABYLON from "babylonjs"
import { Panel } from "../../classes/3D/Panel"
import { Global } from "../../classes/global"

export class HandMenu {

	static obsWebXRHand: BABYLON.Observer<BABYLON.WebXRHand>
	static obsControllerAdded: BABYLON.Observer<BABYLON.WebXRInputSource>
	static obsMotionControllerInit: BABYLON.Observer<BABYLON.WebXRAbstractMotionController>
	static obsModelLoaded: BABYLON.Observer<BABYLON.WebXRAbstractMotionController>
	static xrHandFeature: BABYLON.WebXRHandTracking|null = null

	static eventParent =  (event: MessageEvent) => { 
		const data = event.data
		if (data.hasOwnProperty("source")) {
			if (data.source === "spatialParent") {
				if (data.payload === "settings") {
					HandMenu.process()
				}
			}
		}
	}

	static enable() {
		console.log("enable HAND MENU")
		if (!Panel.all["settings"]) {
			window.addEventListener("message",this.eventParent)
		} else {
			HandMenu.process()
		}
	}

	static disable() {
		if (this.obsModelLoaded) this.obsModelLoaded.remove()
		if (this.obsMotionControllerInit) this.obsMotionControllerInit.remove()
		if (this.obsControllerAdded) this.obsControllerAdded.remove()
		if (this.obsWebXRHand) this.obsWebXRHand.remove()
		window.removeEventListener("message",this.eventParent)
		if (this.xrHandFeature) {
			Global.xr.baseExperience.featuresManager.disableFeature(BABYLON.WebXRFeatureName.HAND_TRACKING)
		}
	}

	static process() {
		this.setupHandTracking()
		// Fallback to controller if no hand is detected
		this.setupControllerTracking()
	}

	static setupHandTracking() {
		const featureManager = Global.xr.baseExperience.featuresManager
		try {
			this.xrHandFeature = featureManager.enableFeature(BABYLON.WebXRFeatureName.HAND_TRACKING, "latest", {
				xrInput: Global.xr.input,
				jointMeshes: {
					disableDefaultHandMesh: true,
					invisible: true,
				}
			}) as BABYLON.WebXRHandTracking
		} catch(error) {
			console.log("ERROR HERE 1")
			console.error(error)
		}
		if (this.xrHandFeature) {		
			try {
				HandMenu.obsWebXRHand = this.xrHandFeature!.onHandAddedObservable.add((hand) => {
					if (hand.xrController.inputSource.handedness === "left") {
						Global.xrPointer = hand.getJointMesh(BABYLON.WebXRHandJoint.WRIST)					
						this.panelToHand()
					}
				})
			} catch(error) {
				console.log("ERROR HERE 2")
				console.error(error)
			}	
		}
	}

	static setupControllerTracking() {
		if (Global.xr.input.controllers && Global.xr.input.controllers.length > 0) {
			if (Global.xr.input.controllers[1].motionController) {
				const controller = Global.xr.input.controllers[1]
				if (controller.grip || controller.pointer) {
					Global.xrPointer = controller.grip || controller.pointer
					this.panelToController()
				} else {
					controller.motionController!.onModelLoadedObservable.add(() => {
						Global.xrPointer = controller.grip || controller.pointer
						this.panelToController()
					})
				}
			} else {
				const controller = Global.xr.input.controllers[1]
				this.obsMotionControllerInit = controller.onMotionControllerInitObservable.add((motionController) => {
					if (controller.grip || controller.pointer) {
						Global.xrPointer = controller.grip || controller.pointer
						this.panelToController()
					} else {
						this.obsModelLoaded = motionController!.onModelLoadedObservable.add(() => {
							Global.xrPointer = controller.grip || controller.pointer
							this.panelToController()
						})
					}
				})
			}
		} else {
			this.obsControllerAdded = Global.xr.input.onControllerAddedObservable.add((controller: BABYLON.WebXRInputSource) => {
				this.obsMotionControllerInit = controller.onMotionControllerInitObservable.add((motionController) => {
					this.obsModelLoaded = motionController!.onModelLoadedObservable.add(() => {
						if (controller.inputSource.handedness === "left") {
							Global.xrPointer = controller.grip || controller.pointer
							this.panelToController()
						}
					})
				})
			})
		}
	}

	static panelToController() {
		console.log("PANEL TO CONTROLLER")
		const mesh = Panel.all["settings"].mesh
		mesh!.parent = Global.xrPointer
		mesh!.position = new BABYLON.Vector3(0, 0.05, 0)
		mesh!.rotation = new BABYLON.Vector3(Math.PI, Math.PI/2, Math.PI/2)
		mesh!.scaling.x = 0.15
		mesh!.scaling.y = 0.15
		window["settings" as any] = mesh! as any
	}

	static panelToHand() {
		const mesh = Panel.all["settings"].mesh
		mesh!.parent = Global.xrPointer
		mesh!.position = BABYLON.Vector3.Zero()
		mesh!.scaling.x = 8
		mesh!.scaling.y = 8
		mesh!.rotation = new BABYLON.Vector3(-Math.PI/2,0,Math.PI)
	}
}
