export default function Settings() {
	const Heading = "General Settings"
	const SubHeading1 = "Opacity"
	const SubHeading2 = "Size"
	const SubHeading3 = "Anchor"
	const fontSize1 = {"--spatial-font-size":"55"} as React.CSSProperties
	const fontSize2 = {"--spatial-font-size":"40", textAlign:"left"} as React.CSSProperties

	return(
		<div style={{display:"none"}} data-spatial-parent-name="settings" data-spatial-size={[0.8, 1]} data-spatial-draggable="false">
			<h1 style={fontSize1} data-spatial-parent="settings" data-spatial-position={[0, 0, 0]} data-spatial-size={[1, 1]}>{Heading}</h1>
			<h1 style={fontSize2} data-spatial-parent="settings" data-spatial-position={[0.1, -0.2, 0]} data-spatial-size={[1, 1]}>{SubHeading1}</h1>
			<div data-spatial-color="#ff0000" data-spatial-min="0" data-spatial-max="100" data-spatial-onchange="changedOpacity" data-spatial-value="50" data-spatial-type="slider" data-spatial-parent="settings" data-spatial-position={[0, 0.11, -0.003]} data-spatial-size={[0.7, 0.1]}/>
			<h1 style={fontSize2} data-spatial-parent="settings" data-spatial-position={[0.1, -0.4, 0]} data-spatial-size={[1, 1]}>{SubHeading2}</h1>
			<div data-spatial-color="#00ff00" data-spatial-initial="100" data-spatial-min="50" data-spatial-max="150" data-spatial-onchange="changedSize" data-spatial-value="50" data-spatial-type="slider" data-spatial-parent="settings" data-spatial-position={[0, -0.08, -0.003]} data-spatial-size={[0.7, 0.1]}/>
			<h1 style={fontSize2} data-spatial-parent="settings" data-spatial-position={[0.1, -0.6, 0]} data-spatial-size={[1, 1]}>{SubHeading3}</h1>
			<button id="btnAnchor" style={{color:"#00000000",backgroundColor:"#00000000"}} data-spatial-onclick="toggleAnchor" data-spatial-image="./assets/anchor.svg" data-spatial-parent="settings" data-spatial-position={[0.05, -0.35, -0.003]} data-spatial-size={[0.27, 0.25]}/>
		</div>
	)
}
