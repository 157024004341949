import * as BABYLON from "babylonjs"

export class Eye {

	static speed = 0.05

	bone: BABYLON.Bone
	angleX = 0
	angleY = 0
	currentX = 0
	currentY = 0
	destX = 0
	destY = 0
	greaterX = false
	greaterY = false
	static objEyes: Record<string, Eye> = {}  //All eyes

	constructor(bone: BABYLON.Bone) {
		this.bone = bone
	}

	static bothX(angle: number, correct = false) {
		Eye.objEyes.left.setX(angle)
		Eye.objEyes.right.setX(angle)
		if (correct) {
			setTimeout(
				function() {
					Eye.objEyes.left.setX(0)
					Eye.objEyes.right.setX(0)
				}
			,2000)
		}
	}

	static bothY(angle: number, correct = false) {
		Eye.objEyes.left.setY(angle)
		Eye.objEyes.right.setY(angle)
		if (correct) {
			setTimeout(
				() => {
					Eye.objEyes.left.setY(0)
					Eye.objEyes.right.setY(0)
				}
			,2000)
		}
	}

	rotateX() {
		this.bone.rotate(BABYLON.Axis.Y, this.currentX);
		if (this.greaterX) {
			if (this.destX > this.currentX) this.currentX += Eye.speed;
		} else {
			if (this.destX < this.currentX) this.currentX -= Eye.speed;
		}
	}

	rotateY() {
		this.bone.rotate(BABYLON.Axis.X, this.currentY);
		if (this.greaterY) {
			if (this.destY > this.currentY) this.currentY += Eye.speed;
		} else {
			if (this.destY < this.currentY) this.currentY -= Eye.speed;
		}
	}

	rotate() {
		this.rotateX()
		this.rotateY()
	}

	setX(Xvalue: number) {
		this.destX = Xvalue
		this.greaterX = this.destX > this.currentX
	}

	setY(Yvalue: number) {
		this.destY = Yvalue
		this.greaterY = this.destY > this.currentY
	}
}