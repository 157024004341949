import * as BABYLON from "babylonjs"

type Timer = NodeJS.Timeout|undefined
type NullableFunc = BABYLON.Nullable<()=>void>

//Facial expression class
export class Facial {

	static blinkTarget: BABYLON.MorphTarget
	static blinkStepTimer: Timer
	static blinkTimer: Timer
	static timerExp: Timer
	static blinkGoingUp = true
	static blinkValue = 0
	static busy = false
	static squinting = false
	static isBlinking = false
	static objFacial: Record<string, Facial> = {}  //All facial expressions

	morph: BABYLON.MorphTarget
	id: string
	maxAmount: number
	textureVal = 0
	timer: Timer
	wait: Timer
	goingUp = true
	timeHeld = 1000
	onFinish: NullableFunc = null

	constructor(id: string, morphTarget: BABYLON.MorphTarget, maxAmount: number) {
		this.morph = morphTarget
		this.id = id
		this.maxAmount = maxAmount
	}


	static resetAll() {
		if (Facial.objFacial.hasOwnProperty("squint")) Facial.objFacial.squint.reset()
		if (Facial.objFacial.hasOwnProperty("smile")) Facial.objFacial.smile.reset()
		if (Facial.objFacial.hasOwnProperty("pout")) Facial.objFacial.pout.reset()
	}

	static blink() {
		if (!Facial.isBlinking) {
			if (!Facial.squinting) {
				if (Facial.blinkTarget !== null) {
					Facial.isBlinking = true
					Facial.blinkValue = 0
					Facial.blinkGoingUp = true
					Facial.blinkStepTimer = setInterval(() => {
						Facial.blinkStep()
					}, 20);        
				}
			}
			Facial.blinkTimer = setTimeout(() => {
				Facial.blink()
			}, (Math.random()+1)*1000)
		}
	}

	static blinkStep() {     
		var step = 0.6;
		if (Facial.blinkGoingUp) {
			Facial.blinkValue += step;
			if (Facial.blinkValue >= 1) {
				Facial.blinkValue = 1
				Facial.blinkGoingUp = false
			}
		} else {
			Facial.blinkValue -= step
			if (Facial.blinkValue < 0.0) {
				Facial.blinkValue = 0
				clearTimeout(Facial.blinkStepTimer)
				Facial.blinkGoingUp = true
				Facial.isBlinking = false
			}
		}
		Facial.blinkTarget.influence = Facial.blinkValue
	}

	reset() {
		this.clearTimers()
		this.textureVal = 0
		this.morph.influence = 0
	}

	clearTimers() {
		clearTimeout(this.wait)
		clearTimeout(this.timer)
		this.goingUp = true
	}

	startExpression(timeHeld = 1000, onStart: NullableFunc = null, onFinish: NullableFunc = null) {
		Facial.resetAll()
		var _this = this
		this.timeHeld = timeHeld
		this.reset()
		Facial.busy = true
		if (this.id === "Squint") {
			Facial.squinting = true
		}
		if (onFinish !== null) {
			this.onFinish = onFinish
		}
		if (onStart !== null) {
			onStart()
		}
		this.timer = setInterval(function() {
			_this.nextStep()
		}, 50)
	}

	nextStep() {     
		var step = 0.05
		var currentValue = this.textureVal
		var _this = this
		if (this.goingUp) {
			currentValue += step;
			if (currentValue >= _this.maxAmount) {
				currentValue = _this.maxAmount
				this.goingUp = false
				clearTimeout(this.timer)
				this.wait = setTimeout(() => {
					_this.timer = setInterval(() => {
						_this.nextStep()
					}, 15)
				},_this.timeHeld)
			}
		} else {
			currentValue -= step
			if (currentValue <= 0.0) {
				this.goingUp = true
				clearTimeout(this.timer)
				if (this.onFinish != null) {
					this.onFinish()
					this.onFinish = null
				}
				Facial.busy = false
				if (this.id === "Squint") {
					Facial.squinting = false
				}
			}
		}
		this.textureVal = currentValue
		this.morph.influence = currentValue
	}
}