import { Spatialise } from "./3D/Spatialise"

export default function CompanyDescription() {
	const customStyles = {
		"--spatial-color": "white",
		"--spatial-font-size": "25"
	} as React.CSSProperties
	return(
		<div id="companyDescr">
			<div id="innerBorder"/>
			<div id="companyDescrGrid">
				<div>
					<img data-spatial-orbit={[340, -25, 2]} data-spatial-size={[1,0.6]} alt="Punk Office logo" src="assets/punkofficeVector.svg"/>
				</div>
				<div>
					<p data-spatial-orbit={[20, -20, 2]} style={customStyles}>Welcome to Punk Office, where we specialize in creating immersive 3D websites. 
					We want people to have fun going through your website so they stay longer and end up falling in love with your brand.
					Instead of reading text and looking at pictures, they get to interact with the website with fun game-like interactions.</p>
				</div>
				<div>
					<h1>3D IS THE PREFERRED EXPERIENCE</h1>
				</div>
				<div>
					<p>If we use games as a reference we can compare what users of gaming platform Steam prefer to play:</p>
				</div>
			</div>
			<div className="blurCircle" style={{position:"absolute"}}/>
		</div>
	)
}