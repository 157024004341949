import * as BABYLON from "babylonjs"
import { Global } from "../global"

type dragObserverEvent = {
	delta: BABYLON.Vector3
	position: BABYLON.Vector3
	pickInfo: BABYLON.PickingInfo
}

interface MovableArray {
	[key: number]: Movable
}

export class Movable {

	static currentID = 1
	static all: MovableArray = {}
	static Ymin = 0.4
	static Ymax = 2.0 //2.4
	static maxSpinSpeed = 20
	static dampening = 0.8

	drag = false
	id = 0
	lastDeltaX = 0
	lastDeltaY = 0
	sensitivity = 2
	rotationInertia = 0
	positionInertia = 0
	mxInertiaFrames = 120 // 4 seconds at 60 fps
	frameCount = 0
	sixDofDragBehavior: BABYLON.SixDofDragBehavior
	obsDragStart: BABYLON.Observer<dragObserverEvent>|null = null
	obsDrag: BABYLON.Observer<dragObserverEvent>|null = null
	obsPointer: BABYLON.Observer<BABYLON.PointerInfo>|null = null
	obsRender: BABYLON.Observer<BABYLON.Scene>|null = null
	target: BABYLON.TransformNode|null = null
	meshToTrack: BABYLON.AbstractMesh|null = null
	meshCage: BABYLON.AbstractMesh|null = null
	cageVisible = false

	constructor(meshToTrack: BABYLON.AbstractMesh, target: BABYLON.TransformNode, visible = false) {
		this.target = target
		this.meshToTrack = meshToTrack
		this.cageVisible = visible
		this.createCage()
		this.sixDofDragBehavior = new BABYLON.SixDofDragBehavior()
		this.meshCage!.position = this.target.position.clone()
		this.meshCage!.scaling = this.target.scaling.clone()
		this.meshCage!.addBehavior(this.sixDofDragBehavior)
		this.sixDofDragBehavior.disableMovement = true
		this.enable()
		this.meshCage!.rotation = this.target.rotation.clone()
		this.id = Movable.currentID
		Movable.all[Movable.currentID++] = this
	}

	static clear() {
		for (let key in Movable.all) {
			Movable.all[key].disable()
			delete Movable.all[key]
		}
		Movable.all = {}
		Movable.currentID = 1
	}

	createCage() {
		var boundingInfo = this.meshToTrack!.getBoundingInfo()
		var boundingBox = boundingInfo.boundingBox
	
		// Calculate the dimensions
		var dimensions = boundingBox.extendSize.clone().scale(2)
	
		var mat = new BABYLON.StandardMaterial("matCage_"+this.id, Global.scene)
		var options = {
			width: dimensions.x,
			height: dimensions.y,
			depth: dimensions.z
		}
		// Create a new box mesh with the same dimensions
		var box = BABYLON.MeshBuilder.CreateBox("cage_"+this.id, options, Global.scene)
		box.rotation = new BABYLON.Vector3(0, Math.PI, 0)
		box.scaling = this.target!.scaling.clone()
		box.position = boundingBox.centerWorld
		box.position.x += this.target!.position.y
		box.position.y += this.target!.position.y
		box.position.z += this.target!.position.z
		if (this.cageVisible) {
			box.material = mat
			box.material.alpha = 0.5
		} else {
			box.visibility = 0
		}
		this.meshCage = box
	}

	disable() {
		this.obsDrag!.remove()
		this.obsDragStart!.remove()
		this.obsPointer!.remove()
		this.obsRender!.remove()
		this.sixDofDragBehavior.detach()
		this.meshCage!.dispose(false,true)
	}

	enable() {
		this.obsDragStart = this.sixDofDragBehavior.onDragStartObservable.add((event) => {
			this.lastDeltaX = 0
			this.lastDeltaY = 0
			this.rotationInertia = 0
			this.positionInertia = 0
			this.frameCount = 0
			this.meshCage!.position = this.target!.position.clone()
			this.meshCage!.rotation = this.target!.rotation.clone()
		})

		this.obsDrag = this.sixDofDragBehavior.onDragObservable.add((event) => {
			const deltaX = event.delta.x - this.lastDeltaX
			const deltaY = event.delta.y - this.lastDeltaY
			const newPosition = this.meshCage!.position.y + deltaY / this.sensitivity 
			if ((newPosition > Movable.Ymin) && (newPosition < Movable.Ymax)) {
				this.meshCage!.position.y += deltaY / this.sensitivity
			}
			if (this.meshCage!.position.z > 0) {
				this.meshCage!.rotation.y -= deltaX / 3
			} else {
				this.meshCage!.rotation.y += deltaX / 3
			}

			this.meshCage!.rotation.y += deltaX / 3
			this.target!.position = this.meshCage!.position.clone()
			this.target!.rotation = this.meshCage!.rotation.clone()

			this.rotationInertia = deltaX
			this.positionInertia = deltaY / this.sensitivity
			this.lastDeltaX = event.delta.x
			this.lastDeltaY = event.delta.y
		})

		this.obsPointer = Global.scene.onPointerObservable.add((evt) => {
			if (evt.type === BABYLON.PointerEventTypes.POINTERDOWN) {	
				if (evt.pickInfo?.pickedMesh) {
					const pickedMesh = evt.pickInfo!.pickedMesh
					let shouldDrag = false
					if (pickedMesh === this.meshToTrack) {
						shouldDrag = true
					}
					this.drag = shouldDrag
				}
			} else if (evt.type === BABYLON.PointerEventTypes.POINTERUP) {
				this.drag = false
			}
		})

		this.obsRender = Global.scene.onBeforeRenderObservable.add(() => {
			if (this.frameCount < this.mxInertiaFrames) {
				if (
					Math.abs(this.rotationInertia) > 0.0001 ||
					Math.abs(this.positionInertia) > 0.0001
				) {
					this.rotationInertia *= Movable.dampening // Dampening the rotation inertia over time
					this.positionInertia *= Movable.dampening // Dampening the position inertia over time
					if (this.meshCage!.position.z > 0) {
						this.meshCage!.rotation.y -= this.rotationInertia * 4
					} else {
						this.meshCage!.rotation.y += this.rotationInertia * 4
					}
					const newPosition = this.meshCage!.position.y + this.positionInertia 
					if ((newPosition > Movable.Ymin) && (newPosition < Movable.Ymax)) {
						console.log("new position: "+newPosition)
						this.meshCage!.position.y = newPosition
						this.target!.position = this.meshCage!.position.clone()
					}
					this.target!.rotation = this.meshCage!.rotation.clone()
					this.frameCount++
				} else {
					this.rotationInertia = 0
					this.positionInertia = 0
					this.frameCount = 0
				}
			}
		})
	}
}
