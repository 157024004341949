import { TextBlock3D } from "../../classes/3D/TextBlock3D"
import { ImageBlock } from "../../classes/3D/ImageBlock"
import { RangeSlider } from "../../classes/3D/RangeSlider"
import { Panel } from "../../classes/3D/Panel"
import { Button } from "../../classes/3D/Button"

export function Spatialise() {
	console.log("Spatialise")
	const spatialNodes = document.querySelectorAll('[data-spatial],[data-spatial-orbit],[data-spatial-parent],[data-spatial-parent-name],[data-spatial-position],[data-spatial-size],[data-spatial-margin]')
	spatialNodes.forEach((child) => processElement(child))
	Panel.setupEventListener()
}

export function clearSpatial() {
	console.log("Clear spatial")
	ImageBlock.clear()
	TextBlock3D.clear()
	Panel.clear()
	Button.clear()
}

function processElement(child: ChildNode) {
	if (child.nodeType === 1) {
		const element = child as HTMLElement 
		if (element.hasAttribute("data-spatial-parent-name")) {
			Panel.create(child)
		} else if (element.hasAttribute("data-spatial-type")) {
			const spatialType = element.getAttribute("data-spatial-type")
			if (spatialType === "slider") {
				RangeSlider.create(child)
			}
		} else {
			switch(child.nodeName) {
				case "P":
				case "DIV":
				case "SPAN":
				case "H1":
					TextBlock3D.create(child)
					break
				case "IMG":
					ImageBlock.create(child)
					break
				case "BUTTON":
					Button.create(child)
					break
				default:
					console.log("Not valid spatial element")
			}
		}
	}
}