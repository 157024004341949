import { useRef, useEffect } from "react"
import { Global } from "../classes/global"
import Hls from "hls.js"

let hls: Hls

function VideoPlayer({video} : {video: string}) {
	const videoNode = useRef<HTMLVideoElement|null>(null)
	const refIsPlaying = useRef(false)

	useEffect(() => {
		console.log("Render video player")
		Global.video = videoNode.current
		if (Hls.isSupported()) {
			console.log("HLS supported")
			if (!hls) {
				hls = new Hls()
				hls.loadSource(video)
				hls.attachMedia(videoNode.current!)
				hls.on(Hls.Events.BUFFER_APPENDED, () => {
					if (!refIsPlaying.current) {
						videoNode.current!.play()
						refIsPlaying.current = true
					}
				})
				hls.on(Hls.Events.MANIFEST_PARSED, () => {
					videoNode.current!.pause()
				})
			}
        } else if (videoNode.current!.canPlayType('application/vnd.apple.mpegurl')) {
            videoNode.current!.src = video
            videoNode.current!.addEventListener('loadeddata', () => {
				if (!refIsPlaying.current) {
					videoNode.current!.play()
					refIsPlaying.current = true
				}
            })

            videoNode.current!.addEventListener('loadedmetadata', () => {
                videoNode.current!.pause()
            })
        }
	})

	return <video id="videoBanner" ref={videoNode} playsInline={true} src={'assets/video/banner.m3u8'} muted={true} loop={true} className="video-js" />
}

export default VideoPlayer
