import * as BABYLON from "babylonjs"
import * as GUI from "babylonjs-gui"
import { TextBlock3D } from "./TextBlock3D"
import { ImageBlock } from "./ImageBlock"
import { Button } from "./Button"
import { tuple, pair, Global } from "../global"

const accentColour = "white"

type spatialMessage = {
	source: string,
	payload: string
}

type Block = TextBlock3D|ImageBlock|Button

interface PanelArray {
	[key: string]: Panel
}

export class Panel {
	static currentID = 1
	static all: PanelArray = {}
	static isAnchored = false 
	id = 0
	name = ""
	mesh: BABYLON.Mesh|null = null
	children: Array<Block> = []
	size: pair = [1,1]
	position: tuple = [0,0,0]
	orbit: tuple = [0,0,0]
	texture: GUI.AdvancedDynamicTexture|null = null
	gui: GUI.Rectangle|null = null
	nodeType = ""
	isDraggable = true
	scaler = 1.0
	opacity = 0.5

	static create(child: ChildNode) {
		new Panel(child)
	}

	constructor(child: ChildNode) {
		this.id = Panel.currentID++
		const element = child as HTMLElement 
		this.nodeType = child.nodeName
		if (element.hasAttribute("data-spatial-size")) this.size = JSON.parse("["+element.getAttribute("data-spatial-size")!+"]")
		if (element.hasAttribute("data-spatial-pos")) this.position = JSON.parse("["+element.getAttribute("data-spatial-pos")!+"]")
		if (element.hasAttribute("data-spatial-orbit")) this.orbit = JSON.parse("["+element.getAttribute("data-spatial-orbit")!+"]")
		if (element.hasAttribute("data-spatial-draggable")) this.isDraggable = (element.getAttribute("data-spatial-draggable")!.toLowerCase() === "true")
		this.name = element.getAttribute("data-spatial-parent-name")!
		Panel.all[this.name] = this
		this.makeMesh()
		this.addGUI()
		if (this.isDraggable) this.makeDraggable()
		this.notify()
	}

	static setupEventListener() {
		window.addEventListener("message",Panel.parseMessage)
	}

	static parseMessage(event: MessageEvent) {
		const data = event.data
		if (data.hasOwnProperty("source")) {
			if (data.source === "changedOpacity") {
				const newOpacity = data.payload as number
				Panel.changeOpacityAll(newOpacity)
			} else if (data.source === "changedSize") {
				const newSize = data.payload as number
				Panel.resizeAll(newSize)
			} else if (data.source === "toggleAnchor") {
				Panel.anchor()
			}
		}	
	}

	static anchor() {
		console.log("calling ANCHOR")
		const heightDiff = Global.scene.activeCamera!.position.y + Global.floor + 0.5
		if (Panel.isAnchored) {
			Global.xrNode.parent = null
			Global.xrNode.position.y = 0
			Global.xrNode.rotation.y = 0
		} else {
			Global.xrNode.position.y = -Global.scene.activeCamera!.position.y
			Global.xrNode.rotation.y = -(Global.scene.activeCamera as BABYLON.WebXRCamera).rotationQuaternion.toEulerAngles().y
			Global.xrNode.parent = Global.xr.baseExperience.camera	
		}
		Panel.isAnchored = !Panel.isAnchored
	}

	static clear() {
		for (let key in Panel.all) {
			Panel.all[key].mesh!.dispose(false, true)
			Panel.all[key].mesh = null
			delete Panel.all[key]
		}
		Panel.all = {}
		Panel.currentID = 1
	}

	static resizeAll(value: number) {
		console.log("Resize all: "+value)
		for (let key in Panel.all) {
			if (key !== "settings") Panel.all[key].resize(value)
		}
	}

	static changeOpacityAll(value: number) {
		console.log("Opacity all: "+value)
		for (let key in Panel.all) Panel.all[key].changeOpacity(value)
	}


	resize(value: number) {
		this.scaler = value
		this.mesh!.scaling = new BABYLON.Vector3(this.scaler, this.scaler, this.scaler)
	}

	changeOpacity(value: number) {
		this.opacity = value
		this.gui!.alpha = value
	}

	makeMesh() {
		this.mesh = BABYLON.MeshBuilder.CreatePlane("spatial",{ width: this.size[0], height: this.size[1] }, Global.scene)
		this.mesh.id = "panel_" + this.id
		if (!((this.orbit[0] === 0) && (this.orbit[1] === 0) && (this.orbit[2] === 0))) {
			this.mesh.position = new BABYLON.Vector3(0, Global.scene.activeCamera!.position.y + Global.floor, this.orbit[2])
			const pivot = Global.scene.activeCamera!.position
			this.mesh.rotateAround(pivot, BABYLON.Axis.X, this.orbit[1] * Math.PI / 180)
			this.mesh.rotateAround(pivot, BABYLON.Axis.Y, this.orbit[0] * Math.PI / 180)
		}
		if (this.name !== "settings") this.mesh.parent = Global.xrNode
	}

	notify() {
		const msg: spatialMessage = {
			source: "spatialParent",
			payload: this.name
		}
		postMessage(msg,"*")
	}

	addGUI() {
		this.texture = GUI.AdvancedDynamicTexture.CreateForMesh(this.mesh!, 1024, 1024)
		this.gui = new GUI.Rectangle()
		this.gui.width = "100%"
		this.gui.height = "100%"
		this.gui.background = "#FFFFFFFF"
		this.gui.cornerRadius = 20
		this.gui.alpha = this.opacity
		this.texture.addControl(this.gui)
		this.mesh!.isVisible = true
		this.mesh!.renderingGroupId = 1
	}

	makeDraggable() {
		const dragBehaviour = new BABYLON.SixDofDragBehavior()
		this.mesh!.addBehavior(dragBehaviour)
		dragBehaviour.onDragStartObservable.add(() => {
			this.gui!.thickness = 6 // Border thickness
			this.gui!.color = accentColour // Border color
		})

		this.mesh!.actionManager = new BABYLON.ActionManager(Global.scene)

		dragBehaviour.onDragEndObservable.add(() => {
			let euler = this.mesh!.rotationQuaternion!.toEulerAngles()
			euler.z = 0
			this.mesh!.rotationQuaternion = BABYLON.Quaternion.FromEulerVector(euler)
			this.gui!.thickness = 0 // Reset thickness to remove the border			
		})
	}
}
