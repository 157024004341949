import { useEffect, useRef } from "react"
import * as BABYLON from "babylonjs"
import { XRpreview } from "../../classes/3D/XR"
import { Global } from "../../classes/global"

let engine: BABYLON.Engine|null = null
let scene: BABYLON.Scene
let isSpinning = true
let cameraRotate: BABYLON.ArcRotateCamera
let spinTimer: NodeJS.Timeout
let meshBody: BABYLON.AbstractMesh

const rotateSpeed = 2500
const spinTimeLimit = 4000

function setupArcRotateCamera(canvas: HTMLCanvasElement, target: BABYLON.AbstractMesh) {
	const radius = 25.0
	const beta = 1.4
	const alpha = -Math.PI / 2
	cameraRotate = new BABYLON.ArcRotateCamera("Camera", alpha, beta, radius, new BABYLON.Vector3(0, 0, 0), scene)
	cameraRotate.lowerBetaLimit = beta - 0.6
	cameraRotate.upperBetaLimit = beta + 0.6
	cameraRotate.lowerRadiusLimit = radius - 3
	cameraRotate.upperRadiusLimit = radius + 3
	cameraRotate.panningSensibility = 0
	cameraRotate.minZ = 0.1
	cameraRotate.setTarget(target)
	cameraRotate.attachControl(canvas)
}

function setupLighting() {
	const lightHemi = new BABYLON.HemisphericLight("HemiLight", new BABYLON.Vector3(0, 1, 0), scene)
	lightHemi.intensity = 1
}

function setupEvents(container: HTMLDivElement) {
	console.log("SETUP EVENTS")
	scene.onPointerDown = ((evt: BABYLON.IPointerEvent, piInfo: BABYLON.PickingInfo, type: BABYLON.PointerEventTypes) => {
		clearTimeout(spinTimer)
		isSpinning = false
	})
	scene.onPointerUp = ((evt: BABYLON.IPointerEvent, piInfo: BABYLON.Nullable<BABYLON.PickingInfo>, type: BABYLON.PointerEventTypes) => {
		isSpinning = false						
		spinTimer = setTimeout(() => {
			isSpinning = true
		}, spinTimeLimit)
	})
	window.addEventListener("resize", () => {
		const isHidden = container.offsetParent === null
		if (!isHidden) {
			if (engine) engine!.resize()
		}
	})					
}

function replaceTexture() {
	const texHighRes = new BABYLON.Texture("assets/3D/marcus/diffuse.png", undefined, undefined, undefined, undefined, () => {
		const matBody = meshBody.material as BABYLON.PBRMaterial
		matBody.albedoTexture = texHighRes
	})
}

function screencap() {
	BABYLON.Tools.CreateScreenshotUsingRenderTarget(engine!, cameraRotate, {
		height: 1000,
		width: 800,
	})
}

export default function Setup3D({container, isLoaded} : {container: HTMLDivElement, isLoaded: () => void}) {
	const refCanvas = useRef<HTMLCanvasElement>(null)

	useEffect(() => {
		if (!engine) {
			engine = new BABYLON.Engine(refCanvas.current, true)
			engine.setHardwareScalingLevel(0.5)
			BABYLON.SceneLoader.ShowLoadingScreen = false
			BABYLON.SceneLoader.Load("assets/3D/marcus/", "marcus.babylon", engine, (newScene) => {
				newScene.executeWhenReady(async () => {
					scene = newScene
					meshBody = scene.getMeshByName("body")!
					meshBody.position.y = 1.3
					meshBody.position.z = 1.2
					meshBody.isPickable = true
					window["scene" as any] = scene as any
					Global.meshMarcus = meshBody
					scene.clearColor = new BABYLON.Color4(0, 0, 0, 0);
					(scene.meshes[0].material! as BABYLON.PBRMaterial).unlit = true
					replaceTexture()
					setupArcRotateCamera(refCanvas.current!, meshBody)
					setupLighting()
					if (container) setupEvents(container)
					isLoaded()
					await XRpreview(scene, refCanvas.current!)
					engine!.runRenderLoop(() => {
						scene.render()
						if (process.env.REACT_APP_VR_PREVIEW_FLAG !== "true") {
							if (isSpinning) cameraRotate.alpha += engine!.getDeltaTime() / rotateSpeed
						}
					})
					console.log("DISPATCHING scene event")
				}, true)
			})
		}
		return () => {
			console.log("Dispose engine")
			if (engine) engine!.dispose()
		}
	})

	return(
		<canvas id="myCanvas" ref={refCanvas}/>
	)
}