import { useState, useLayoutEffect, useRef } from "react"

function DemoItem({panelIdx, heading, imageURL, imageAlt, siteURL}:{panelIdx: number, heading:string, imageURL:string, imageAlt:string, siteURL:string}) {
	const fullImageURL = "assets/demos/"+imageURL
	const panelName = "panel"+panelIdx 
	const h1Styles = {
		"--spatial-font-size": "46",
		"--spatial-color": "#00008B"
	} as React.CSSProperties

	function openURL() {
		window.open(siteURL, '_blank')
	}

	return(
		<div className="demoItem">
			<img data-spatial-parent={panelName} data-spatial-size={[0.9,0.75]} data-spatial-position={[0,-0.075,0]} src={fullImageURL} onClick={openURL} alt={imageAlt}/>
			<img style={{display:"none"}} data-spatial-pickable="true" data-spatial-target={siteURL} data-spatial-size={[0.13,0.13]} data-spatial-position={[0.4,0.4,-0.003]} data-spatial-parent={panelName} src="assets/link.png"/>
			<div><h1 style={h1Styles} data-spatial-parent={panelName} data-spatial-size={[0.85,1]} data-spatial-position={[-0.07,0,0]}>{heading}</h1></div>
		</div>
	)
}

function demoWidth() {
	const elDemoItem = document.querySelectorAll(".demoItem")[0]! as HTMLDivElement
	return elDemoItem.offsetWidth
}

export default function Demos() {
	let eventIsAdded = false
	const refScroller = useRef<HTMLDivElement>(null)
	const refLeft = useRef<HTMLDivElement>(null)
	const refRight = useRef<HTMLDivElement>(null)
	const [page, setPage] = useState(0)
	const [atMaxPage, setAtMaxPage] = useState(false)

	const customStyles = {
		"display":"none",
		"--spatial-color": "white",
		"--spatial-font-size": "140"
	} as React.CSSProperties

	function updateSize() {
		let newPage = page
		if (page > 0) {
			let width = demoWidth()
			if (window.innerWidth > 1000) {
				if (page === 1) {
					newPage = 0
					setAtMaxPage(false)
				} else if (page === 2) {
					newPage = 2
					setAtMaxPage(true)
				} else if (page === 3) {
					newPage = 2
					setAtMaxPage(true)
				}
			} else {
				setAtMaxPage(newPage > 2)
			}
			refScroller.current!.scrollLeft = width * newPage
			setPage(newPage)
		}
	}

	useLayoutEffect(() => {
		if (!eventIsAdded) window.addEventListener('resize', updateSize)
		eventIsAdded = true
    	return () => window.removeEventListener('resize', updateSize)
	},[page])

	// NEED TO ADD LINKS
	const projects = [
		{title:"Cat clothing",imageURL:"cat3d.jpg",imageAlt:"Sphynx cat wearing a top",siteURL:"https://punkoffice.com/cat3d"},
		{title:"Sportswear",imageURL:"cycling.jpg",imageAlt:"Cyclist on bike",siteURL:"https://punkoffice.com/designlab"},
		{title:"Wednesday Addams",imageURL:"wednesday.jpg",imageAlt:"Control Wednesday Addams",siteURL:"https://punkoffice.com/wednesday"},
		{title:"Squid Game",imageURL:"squidgame.jpg",imageAlt:"Game version of Squid Game show",siteURL:"https://punkoffice.com/squidgame"}
	]

	const arrDemos = projects.map(({title, imageURL, imageAlt, siteURL}, index) => 
		<DemoItem panelIdx={(5+index)} key={index} heading={title} imageURL={imageURL} imageAlt={imageAlt} siteURL={siteURL}/>
	)

	function scrollLeft() {
		if (page > 0) {
			let width = demoWidth()
			if (window.innerWidth > 1000) {
				setPage(0)
				width = 0
			} else {
				width = (page-1)*width
				setPage(() => page-1)
			}
			setAtMaxPage(false)
			if (refScroller.current) {
				refScroller.current!.scrollTo({ left: width, behavior: "smooth" })
			}
		}
	}
	
	function scrollRight() {
		if (page < 3) {
			if (refScroller.current) {
				console.log("Screen width: "+window.innerWidth)
				let width = demoWidth()
				if (window.innerWidth > 1000) {
					width = 2 * width
					setAtMaxPage(true)
					setPage(2)
				} else {
					width = (page+1)*width
					setAtMaxPage(page === 2)
					setPage(() => page+1)
				}
				refScroller.current!.scrollTo({ left: width, behavior: "smooth" })
			}
		}
	}

	return(
		<div style={{position:"relative"}}>
			<h1 style={customStyles} data-spatial-orbit={[180, -15, 2]}>Demos</h1>
			<div id="blob6" className="blob" style={{top:"-5vw",left:"0"}}>
				<img alt="Sixth blob" src="assets/blobs6.svg"/>
			</div>
			<div id="demos">		
				<div id="demosGrid">
					<div><h1>Demo projects</h1></div>
					<div id="demoArrows">
						<div className={`arrow ${page === 0?'':'arrowEnabled'}`} ref={refLeft} id="arrowLeft" onClick={scrollLeft}/>
						<div className={`arrow ${atMaxPage?'':'arrowEnabled'}`} ref={refRight} id="arrowRight" onClick={scrollRight}/>
					</div>
				</div>			
				<div ref={refScroller} id="demosContainer">
					<div id="demosScroller">
						{arrDemos}
					</div>
				</div>
			</div>
		</div>
	)
}