import * as BABYLON from "babylonjs"
import "babylonjs-loaders"
import { Global } from "../global"
import { cleanupXR, importMaddie, importMaddieXR } from "../../components/3D/setupMaddie"
import { clearSpatial } from "../../components/3D/Spatialise"
import { Movable } from "./Movable"
import { HandMenu } from "../../components/3D/handMenu"
import { ImageBlock } from "./ImageBlock"

let cameraFree: BABYLON.FreeCamera
const lookAt = new BABYLON.Vector3(0, 1.5, 0)

export let xr: BABYLON.WebXRDefaultExperience
export let engine: BABYLON.Engine
let scene: BABYLON.Scene

function setupFreeCamera(scene: BABYLON.Scene, canvas: HTMLCanvasElement) {
	cameraFree = new BABYLON.FreeCamera(
		"FreeCamera",
		BABYLON.Vector3.Zero(),
		scene
	)
	cameraFree.position.x = 0
	cameraFree.position.y = 1.5
	cameraFree.position.z = -0.5
	cameraFree.inertia = 0
	cameraFree.setTarget(lookAt)
}

export async function XRpreview(XRscene: BABYLON.Scene, canvas: HTMLCanvasElement) {
	scene = XRscene
	xr = await scene.createDefaultXRExperienceAsync({
		uiOptions: { sessionMode: "immersive-ar" }
	})
	Global.scene = scene
	Global.xr = xr
	if (xr.baseExperience) {
		xr.baseExperience.sessionManager.onXRSessionInit.add(() => {
			console.log("ENTERED XR")
			Global.video!.pause()
			xr.teleportation.detach()
			Global.meshMarcus.rotation = new BABYLON.Vector3(-Math.PI/2, 0, 0)
			Global.meshMarcus.scaling = new BABYLON.Vector3(0.06,0.06,0.06)
			Global.meshMarcus.position.y = 1.3
			new Movable(Global.meshMarcus, Global.meshMarcus)
		})
		xr.baseExperience.sessionManager.onXRReady.add(async () => {
			console.log("XR Ready")
			await importMaddieXR(Global.scene)
			const maddieParent = Global.scene.getTransformNodeByName("maddieParent")!
			const sourceMesh = Global.scene.getMeshByName("MaddieBody")!
			new Movable(sourceMesh, maddieParent)
			Global.xrNode = new BABYLON.TransformNode("xrNode", Global.scene)
			Global.xrNode.rotation = BABYLON.Vector3.Zero()
			const event = new Event("sceneLoadedEvent")
			window.dispatchEvent(event)
			HandMenu.enable()
			ImageBlock.setupPicking()
		})
		xr.baseExperience.sessionManager.onXRSessionEnded.add(() => {
			console.log("EXITED XR")
			Global.video!.play()
			Global.meshMarcus.scaling = new BABYLON.Vector3(1, 1, 1)
			Movable.clear()
			cleanupXR()
			clearSpatial()
			let toDelete: Array<string> = []
			Global.scene.meshes.forEach(mesh => {
				if (mesh.name !== "body") toDelete.push(mesh.name)
			})
			toDelete.forEach(meshName => {
				if (Global.scene.getMeshByName(meshName)) Global.scene.getMeshByName(meshName)!.dispose(false, true)
			})
			Global.scene.getTransformNodeByName("maddieParent")!.dispose(false, true)
			Global.xrNode.dispose()
			HandMenu.disable()
			importMaddie()
		})
	}

	if (process.env.REACT_APP_VR_PREVIEW_FLAG === "true") {
		scene.clearColor = new BABYLON.Color4(0, 0, 0, 1)
		canvas.style.position = "fixed"
		canvas.style.width = "100%"
		canvas.style.height = "100%"
		canvas.style.top = "0"
		canvas.style.left = "0"
		canvas.style.zIndex = "2"
		Global.meshMarcus.scaling = new BABYLON.Vector3(0.06,0.06,0.06)
		setupFreeCamera(scene, canvas)
		scene.activeCamera = cameraFree
		cameraFree.attachControl(canvas)
		cameraFree.setTarget(lookAt)
		engine = scene.getEngine() as BABYLON.Engine
		engine.resize()
	}
}